.home-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 5% 10%;
    background-color: #f0f0f0; 
    padding: 20px;
    border-radius: 10px;
  }
  
  .category-box {
    flex: 1 1 18%; 
    margin: 10px;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .category-box:hover {
    transform: scale(1.05); 
  }
  
  .category-box p {
    color: white;
    font-size: 18px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .category-box {
      flex-basis: 100%; 
    }
  }
  