.create-products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.file-input-container {
    border: 2px dashed #ccc;
    width: 60%;
    padding: 10px;
    text-align: center;
}

.uploaded-image {
    width: 100px;
    height: auto;
}

select, input, textarea {
    width: 60%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
