/* Navbar.css */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  height: 100px; /* تم تعديل الارتفاع ليستوعب الشعار */
}

.navbar-logo img {
  height: auto;
  max-height: 80px; /* تم تعديل الحد الأقصى لارتفاع الشعار */
  width: 100%;
  max-width: 150px;
}

.navbar-icon {
  color: #333;
  text-decoration: none;
  display: flex;
  font-size: 3.5rem; /* تم تكبير حجم الأيقونة */
  align-items: center;
}

.navbar-icon .fa-shopping-cart {
  font-size: 0100rem; /* تم تكبير حجم الأيقونة */
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }

  .navbar-logo {
    margin-bottom: 0; /* إزالة الهامش السفلي */
  }

  .navbar-logo img {
    max-width: 120px; /* تصغير الشعار ليناسب الشاشات الصغيرة */
    max-height: 60px; /* تعديل ارتفاع الشعار على الشاشات الصغيرة */
  }

  .navbar-icon .fa-shopping-cart {
    font-size: 2.8rem; /* تعديل حجم الأيقونة على الشاشات الصغيرة */
  }
}
